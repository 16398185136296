import React from "react";

const CustomButton = ({ onClick, text }) => {
  return (
    <div className="custom-button" onClick={onClick}>
      {text}
    </div>
  );
};

export default CustomButton;
