import React from "react";

const Done = ({ db, setCurrentPage, handleGetRoomCharges }) => {
  const handleClick = () => {
    handleGetRoomCharges("", "", db, "");
    setCurrentPage(1);
  };

  return (
    <div onClick={handleClick} className="done-wrapper">
      <button className="done-button">Done</button>
    </div>
  );
};

export default Done;
