import { useState, useEffect } from "react";

import svgX from "./assets/x.svg";

import SearchPage from "./components/SearchPage";
import ProceessingPage from "./components/ProceessingPage";
import SuccessPage from "./components/SuccessPage";
import FailPage from "./components/FailPage";

import { Route, useLocation } from "react-router-dom";

import * as axiosFns from "./axios";

const App = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [populateValue, setPopulateValue] = useState("");
  const [db, setDb] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    "Unknown error. Please contact your PMS provider."
  );
  const { search } = useLocation();
  let accountId = "";
  let shopId = "";
  let saleId = "";
  let workorderID = "";
  let registerID;
  let roomId = "";
  let refundId = "0";
  let operationName = "";
  let accountDataId = "";
  let guestName = "";
  let reservationId = "";
  let isPopulate = "";
  let customerId = "";

  if (!!search) {
    try {
      customerId = search
        .split("&")
        .filter((item) => item.indexOf("customerID") > -1)[0]
        .split("=")[1];
    } catch (err) {}

    try {
      accountId = search
        .split("&")
        .filter((item) => item.indexOf("accountID") > -1)[0]
        .split("=")[1];

      saleId = search
        .split("&")
        .filter((item) => item.indexOf("saleID") > -1)[0]
        .split("=")[1];
      
      try{
        workorderID = search // sale_id (za get employees)
        .split("&")
        .filter((item) => item.indexOf("saleID") > -1)[0]
        .split("=")[1];
      } catch (err) {}

      console.log(workorderID);
      shopId = search
        .split("&")
        .filter((item) => item.indexOf("shopID") > -1)[0]
        .split("=")[1];

    registerID = search
        .split("&")
        .filter((item) => item.indexOf("registerID") > -1)[0]
        .split("=")[1];
    } catch (err) {}

    try {
      refundId = search
        .split("&")
        .filter((item) => item.indexOf("refund") > -1)[0]
        .split("=")[1];
    } catch (err) {}
    try {
      operationName = search
        .split("&")
        .filter((item) => item.indexOf("operation") > -1)[0]
        .split("=")[1];
    } catch (err) {}
  }

  useEffect(() => {
    if (operationName.indexOf("populate") > -1)
      axiosFns.getPopulate(
        setPopulateValue,
        accountId,
        customerId,
        operationName
      );

    handleGetSearchData();
  }, []);

  useEffect(() => {
    if (!populateValue) return;
    axiosFns.getSearchData(
      accountId,
      shopId,
      registerID,
      operationName,
      workorderID, // sale_id
      populateValue,
      setDb,
      setLoading
    );
    setSearchValue(populateValue);
  }, [populateValue]);

  const handleGetSearchData = () =>
    axiosFns.getSearchData(
      accountId,
      shopId,
      registerID,
      operationName,
      workorderID, // sale_id
      searchValue,
      setDb,
      setLoading
    );

  const handleGetRoomCharges = (
    roomIdInput,
    accountDataIdX,
    guestNameX,
    reservationIdX
  ) => {
    roomId = roomIdInput;
    accountDataId = accountDataIdX;
    guestName = guestNameX;
    reservationId = reservationIdX;
    axiosFns.getRoomCharges(
      accountId,
      saleId,
      roomId,
      accountDataIdX,
      guestNameX,
      reservationIdX,
      refundId,
      operationName,
      setCurrentPage,
      setErrorMsg
    );
  };
  const handleGetRoomChargesRetry = () => {
    axiosFns.getRoomCharges(
      accountId,
      saleId,
      roomId,
      accountDataId,
      guestName,
      reservationId,
      refundId,
      operationName,
      setCurrentPage,
      setErrorMsg
    );
  };
  return (
    <Route path="/">
      <div className="app">
        <div>
          <p onClick={() => window.history.back()} className="go-back-text">
            Go back
          </p>
        </div>
        <div className="main-app">
          <div className="header">
            <p>Employee List</p>
            <div onClick={() => window.history.back()} className="x-wrapper">
              <img src={svgX} alt="x" />
            </div>
          </div>

          <div className="main-content">
            {currentPage === 0 && (
              <SearchPage
                db={db}
                setDb={setDb}
                loading={loading}
                handleGetRoomCharges={handleGetRoomCharges}
                setCurrentPage={setCurrentPage}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            )}
            {currentPage === 1 && <ProceessingPage />}
            {currentPage === 2 && <SuccessPage />}
            {currentPage === 3 && (
              <FailPage
                handleGetRoomChargesRetry={handleGetRoomChargesRetry}
                setCurrentPage={setCurrentPage}
                errorMsg={errorMsg}
              />
            )}
          </div>
        </div>
      </div>
    </Route>
  );
};

export default App;
