import React from "react";
import lodash from "lodash";

import Done from "./searchPage/Done";
import Search from "./searchPage/Search";
import Table from "./searchPage/Table";

const SearchPage = ({
  db,
  setDb,
  loading,
  handleGetRoomCharges,
  setCurrentPage,
  searchValue,
  setSearchValue,
}) => {
  const sorted = lodash.sortBy(db, (o) => parseInt(- o.reservation_id));

  const filtered = sorted.filter(
    (item) =>
      item?.account_id.toLowerCase().indexOf(searchValue.toLocaleLowerCase()) >
        -1 ||
      item.guest_name.toLowerCase().indexOf(searchValue.toLocaleLowerCase()) >
        -1 ||
      item.reservation_id
        .toLowerCase()
        .indexOf(searchValue.toLocaleLowerCase()) > -1
  );

  return (
    <>
      <Search
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        loading={loading}
      />
      <Table setCurrentPage={setCurrentPage} setDb={setDb} db={filtered} />
      <Done
        db={sorted}
        setCurrentPage={setCurrentPage}
        handleGetRoomCharges={handleGetRoomCharges}
      />
    </>
  );
};

export default SearchPage;
