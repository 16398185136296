import React from "react";

import search from "../../assets/search.svg";
import clear from "../../assets/clear.svg";
import oval from "../../assets/oval.svg";

const Search = ({ searchValue, setSearchValue, loading }) => {
  return (
    <div className="search-wrapper">
      <img src={search} alt="search" />
      <input
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        type="text"
        alt="search"
      />
      {searchValue !== "" && (
        <img
          className="clear-img"
          onClick={() => setSearchValue("")}
          src={clear}
          alt="clear"
        />
      )}
      {loading && <img className="loader-img" src={oval} alt="loading" />}
    </div>
  );
};

export default Search;
