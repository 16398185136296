import React from "react";

// export function NewLineToBr({ children = "" }) {
//   return children.split("\n").reduce(function (arr, line) {
//     return arr.concat(line, <br />);
//   }, []);
// }

// onRowClick
// setCurrentPage(1);
// handleGetRoomCharges(
//   item.reservation_id,
//   item.account_id,
//   item.guest_name,
//   item.reservation_id
// );

const Row = ({ item, setDb }) => {
  const { account_id, guest_name, reservation_id } = item;

  const bool = reservation_id === "1" ? true : false;

  const handleClick = () => {
    setDb((old) => [
      ...old.filter((i) => i.account_id !== item.account_id),
      { ...item, reservation_id: bool ? "0" : "1" },
    ]);
  };

  return (
    <tr onClick={handleClick}>
      <td>
        <p>{account_id}</p>
      </td>
      <td>
        <p>{guest_name}</p>
      </td>
      <td className="check last-td">
        <input type="checkbox" checked={bool} onChange={handleClick} />
      </td>
    </tr>
  );
};

const Table = ({ db, setDb }) => {
  //let sortedData = db.slice().sort((a, b) => b.account_id - a.account_id);
  // console.log(setDb);
  return (
    <div className="table-wrapper">
      <table className="table">
        <tbody>
          {db.length > 0
            ? db.map((item) => (
                <Row key={item.account_id} item={item} setDb={setDb} />
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
